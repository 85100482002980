// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,

    // Core
    selected: null,
    outline: [],
    stats: [],
    kpis: [],
    readout: {
      name: "Campaign Breakdown",
      type: "table",
      data: {
        headers: [
          {
            display: "Campaign Name",
            name: "campaign_name"
          },
          {
            display: "Return on Ad Spend",
            name: "return_on_ad_spend"
          },
          {
            display: "Total Sales",
            name: "total_sales"
          },
          {
            display: "Total Spend",
            name: "total_spend"
          },
          {
            display: "Projected ROI",
            name: "projected_roi"
          }
        ],
        rows: []
      }
    },
    filters: {
      timeframe: {
        startDate: "2024-04-06",
        endDate: "2024-06-01"
      }
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIITIALIZE_REPORT: {
          console.log("Initializing report...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true
          };
        }
        case actionTypes.SET_ACTIVE_REPORT: {
          console.log("Setting active report...", action.payload);

          // Update state
          return {
              ...state,
              selected: action.payload
          };
        }
        case actionTypes.SET_FILTER_TIMEFRAME: {
          console.log("Setting filter timeframe...", action.payload);

          // Update state
          return {
              ...state,
              filters: {
                timeframe: action.payload
              }
          };
        }
        case actionTypes.SET_READOUT: {
          console.log("Setting report readout data...", action.payload);

          // Update state
          return {
              ...state,
              readout: action.payload
          };
        }
        default:
            return state;
    }
};

export default reportReducer;


/*


stats: [
  {
    id: "@axon/commerce/customers/age",
    name: "18 - 25",
    value: 15,
    display: "Age: 18 - 25"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "25 - 30",
    value: 30,
    display: "Age: 25 - 30"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "30 - 45",
    value: 45,
    display: "Age: 30 - 45"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "45 - 55",
    value: 10,
    display: "Age: 45 - 55"
  },
  {
    id: "@axon/commerce/customers/age",
    name: "55+",
    value: 5,
    display: "Age: 55+"
  }
],


{
  name: "Performance Summary",
  type: "kpi",
  data: [
    {
      name: "Lifetime Value",
      value: "$1,269.25"
    },
    {
      name: "Total Orders",
      value: "5"
    },
    {
      name: "Time to Purchase",
      value: "5.2 Days"
    }
  ]
},



*/
