
const config = {
  "account": {
    "contact": "garrett@sell.design",
    "id": "sell-design",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/sell-design/assets/img/logo-avatar.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/sell-design/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/sell-design/assets/img/brand/logo.svg",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/sell-design/assets/img/brand/logo-white.svg"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://avant-garde.ai",
      "name": "Avant-Garde Capital, LLC"
    }
  },
  "theme": {
    "palette": {
      "color-1": "#ABA59D",
      "color-2": "#000000",
      "color-3": "#C5A36A",
      "color-4": "#4C221E",
      "color-5": "#ABA59D"
    }
  }
}

export default config;
