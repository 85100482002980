/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Button
} from "reactstrap";

// javascipt plugin for creating charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

import {
  chartOptions,
  parseOptions,
  chartExample1
} from "variables/charts.js";

// Get last 7 days
let dates = [];
let values = [];
for (let i = 0; i < 7; i++) {
    // Setup default
    dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    values.push(0);
}

// Chart data placeholders
let chartData = {
  customerActivity: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Matched Customers",
            data: values,
          },
        ],
      };
    }
  }
};

// Query: Customer Activity
async function getCustomerActivity(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get all current customers
  let customerActivityQuery = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
        {
            "id": "Customer Activity",
            "math": "dau",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "properties": [
                {
                    "key": "properties.persona.version = '0.0.6'",
                    "type": "hogql",
                    "value": null
                },
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.config.defaultClient}`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "interval": "day",
    "breakdown": "properties.persona.name",
    "entity_type": "events",
    "breakdown_type": "hogql"
  };

  // Execute query
  const customerActivityResponse = await axios.post(eventsApiUrl, customerActivityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Return values
  return {
    personas: customerActivityResponse.data.result
  };
}

function PersonasChart({ profile, account, knowledge, model, report }) {

  // Default states
  const [activeNav, setActiveNav] = React.useState(2);
  const [activeChartData, setActiveChartData] = React.useState("default");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setActiveChartData(activeChartData === "7-day" ? "30-day" : "7-day");
  };

  // Set KPI values
  const [ totalMatches, setTotalMatches] = React.useState(0);

  // Load persona data
  React.useEffect(() => {
    if (account.initialized) {
      // Customer Activity
      (async () => {
        // Get customer details
        const customerActivity = await getCustomerActivity(account, "2024-03-29", "2024-04-29T23:59:59");
        console.log("Persona Customer Activity: ", customerActivity);

        // Set customer data
        if(customerActivity.personas && customerActivity.personas.length > 0) {
          // Track total matches
          let totalMatchedCustomers = 0;

          // Create dataset (7day)
          let dataset7Days = []
          for(var i = 0; i < customerActivity.personas.length; i ++) {
            // Add data
            console.log(customerActivity.personas[i]);
            dataset7Days.push({
              label: customerActivity.personas[i].label,
              data: customerActivity.personas[i].data.slice(Math.max(customerActivity.personas[i].data.length - 7, 0))
            });

            // Update customer count
            totalMatchedCustomers += _.sum(customerActivity.personas[i].data);
          }

          // Set chart data (7-day)
          chartData.customerActivity['7-day'] = (canvas) => {
            return {
              labels: customerActivity.personas[0].days.slice(Math.max(customerActivity.personas[0].days.length - 7, 0)),
              datasets: dataset7Days
            };
          };

          // Create dataset (30-day)
          let dataset30Days = []
          for(var i = 0; i < customerActivity.personas.length; i ++) {
            // Add data
            dataset30Days.push({
              label: customerActivity.personas[i].label,
              data: customerActivity.personas[i].data
            });
          }

          // Set chart data (30-day)
          chartData.customerActivity['30-day'] = (canvas) => {
            return {
              labels: customerActivity.personas[0].days,
              datasets: dataset30Days
            };
          };

          // Set default flag
          setActiveChartData("30-day");
          setTotalMatches(totalMatchedCustomers);
        }
      })();
    }
  }, [ account.initialized ]);

  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Personas
                </h6>
                <h5 className="h3 text-white mb-0">Recent Customers</h5>
              </div>
              <div className="col">
                <Nav className="justify-content-end" pills>
                  <NavItem className="mr-2 mr-md-0">
                    <NavLink
                      className={classnames("border border-white py-2 px-3", {
                        active: activeNav !== 1,
                      })}
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 1)}
                    >
                      <span className="d-none d-md-block">7 Days</span>
                      <span className="d-md-none">M</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("border border-white py-2 px-3", {
                        active: activeNav !== 2,
                      })}
                      data-toggle="tab"
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 2)}
                    >
                      <span className="d-none d-md-block">30 Days</span>
                      <span className="d-md-none">W</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          New Matched Customers
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>{ totalMatches }</span>
                        <span className="text-success ml-2" style={{
                          fontSize: 11
                        }}>
                          <i className="fa fa-arrow-up" /> 15.84%
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Optimized Lifetime Value
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>$325.84</span>
                        <span className="text-success ml-2" style={{
                          fontSize: 11
                        }}>
                          <i className="fa fa-arrow-up" /> 56.84%
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card className={`card-stats text-white bg-white`}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 text-primary`}
                        >
                          Projected Revenue Growth
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>$235,984.92</span>
                        <span className="text-success ml-2" style={{
                          fontSize: 11
                        }}>
                          <i className="fa fa-arrow-up" /> 36.84%
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                <ListGroup className="list my--3 bg-primary" flush>
                  { knowledge.entities.map((persona, index) => (
                    <ListGroupItem className="bg-primary border-0 px-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className="text-white">{persona.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="info"
                            max="100"
                            value={ (persona.matched && totalMatches > 0) ? (100.0 * persona.matched / totalMatches) : 0 }
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Line
                    data={chartData.customerActivity[activeChartData]}
                    options={chartExample1.options}
                    id="customer-activity"
                    className="chart-canvas"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedPersonasChart = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  model: state.model,
  report: state.report
}))(PersonasChart);

export default ConnectedPersonasChart;
