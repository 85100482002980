export const version = "1.0.0";

export const accountId = "";

export const env = "local";

export const firebaseConfig = {
  apiKey: "AIzaSyDEv3YlERgaYuZ5rqZH5JV370YB-P6L5-Q",
  authDomain: "axon-platform-dev.firebaseapp.com",
  databaseURL: "https://axon-platform-dev-default-rtdb.firebaseio.com",
  projectId: "axon-platform-dev",
  storageBucket: "axon-platform-dev.appspot.com",
  messagingSenderId: "233532545812",
  appId: "1:233532545812:web:4841279d37267e9a198001"
};

export const reduxFirebase = {
  enableLogging: "false",
};

export const atlas = {
  serverUrl: "http://localhost:5001/axon-platform/us-central1",
};

export const build = {
  baseUrl: "http://localhost:3100",
};

export const sdk = {
  baseUrl: "https://localhost:9000",
};

export const configs = {
  baseUrl: "https://axon-config.ngrok.io",
};

export const customer = {
  activityStreamTopicName: "customer-activity",
};

export const agent = {
  ipStackAPIAccessKey: "4d1a98985ea36c7d938ee42f111441bf",
};

export default { version, accountId, env, firebaseConfig, reduxFirebase, atlas, build, sdk, configs, customer, agent };
