/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INIITIALIZE_INSIGHTS,
  SET_ACTIVE_ENTITY,
  SET_PERSONAS
} from 'store/actions';

// Import report blocks
import PersonasChart from "./blocks/PersonasChart.js";

// Query: Customer Activity by Persona
async function getPersonas(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get persona stats
  let personasQuery = {
    "events": [
      {
        "id": "Customer Activity",
        "math": "dau",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        "custom_name": "Total Customer Created",
        "properties": [
            {
                "key": "properties.persona.version = '0.0.6'",
                "type": "hogql",
                "value": null
            },
            {
                "key": "clientId",
                "type": "event",
                "value": [
                    `${account.config.defaultClient}`
                ],
                "operator": "exact"
            }
        ]
      }
    ],
    "date_to": null,
    "display": "ActionsTable",
    "insight": "TRENDS",
    "interval": "month",
    "breakdown": "properties.persona.ref.id",
    "date_from": "all",
    "entity_type": "event",
    "breakdown_type": "hogql"
  }

  // Execute query
  const personasResponse = await axios.post(eventsApiUrl, personasQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Parse persona data
  let personaData = [];
  if(personasResponse.data && personasResponse.data.result.length > 0) {
    for(var i = 0; i < personasResponse.data.result.length; i++) {
      // Extract persona
      personaData.push({
        "id": personasResponse.data.result[i].breakdown_value,
        "total": personasResponse.data.result[i].aggregated_value
      });
    }
  }

  // Return values
  return {
    personas: personaData
  };
}

function Personas({ profile, account, knowledge, insights, report, dispatch }) {
  console.log("Account:", account);
  console.log("Knowledge:", knowledge);

  // Get history
  const history = useHistory();

  // States (Timeframe)
  const [startDate, setStartDate] = React.useState(report.filters.timeframe.startDate);
  const [endDate, setEndDate] = React.useState(report.filters.timeframe.endDate);
  const listRef = React.useRef(null);
  const [activeNav, setActiveNav] = React.useState(1);
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  // Sync customer insights
  useEffect(() => {
    // Load Customer Persona details
    if (account.initialized && !insights.initialized) {
      // Initialize insights
      dispatch({
        type: INIITIALIZE_INSIGHTS,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            },
            clients: {
              list: account.clients,
              selected: (account.clients.length > 0) ? account.clients[0] : null
            }
          }
        }
      });
    }

    // Sync Persona data
    (async () => {
      // Get customer details
      const personaData = await getPersonas(account, "2024-03-29", "2024-04-29T23:59:59");

      // Update Personas
      if(personaData && personaData.personas) {
        // Clone existing personas
        let existingPersonas = knowledge.entities;

        // Iterate over updated persona data
        let updatedPersonas = [];
        for(var i = 0; i < personaData.personas.length; i++) {
          // Find matching persona
          const matchedPersona = _.find(existingPersonas, function (existingPersona) {
            return existingPersona.id == personaData.personas[i].id;
          });

          // Update if matched
          if(matchedPersona) {
            // Update match count
            matchedPersona.matched = personaData.personas[i].total;

            // Add to new personas
            updatedPersonas.push(matchedPersona);
          }
        }

        // Update personas
        dispatch({
          type: SET_PERSONAS,
          payload: updatedPersonas
        });
      }
    })();
  }, [ account ]);

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/customers/personas/view/${entity.id}`);
  }

  return (
    <>
      <KnowledgeHeader
        headline="Customer Activity"
        description="AI personas provide valuable, on-going insights into the needs and behaviors of a specific target audience."
      />
      <Container className="mt--6" fluid>
        <PersonasChart />
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="border-0">
                <div className="col">
                  <h3 className="mb-0">Active Customers</h3>
                </div>
                <div className="col">
                  <Nav className="justify-content-end" pills>
                    <NavItem className="mr-2 mr-md-0">
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 1,
                        })}
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 1)}
                      >
                        <span className="d-none d-md-block">30 Days</span>
                        <span className="d-md-none">M</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames("py-2 px-3", {
                          active: activeNav === 2,
                        })}
                        data-toggle="tab"
                        href="#pablo"
                        onClick={(e) => toggleNavs(e, 2)}
                      >
                        <span className="d-none d-md-block">90 Days</span>
                        <span className="d-md-none">W</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">
                      Name
                    </th>
                    <th className="sort" data-sort="matched" scope="col">
                      Matched Customers
                    </th>
                    <th className="sort" data-sort="value" scope="col">
                      Lifetime Value (Avg.)
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  { knowledge.entities.map((entity, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { entity.name }
                            </a>
                          </h4>
                          <small>

                          </small>
                        </div>
                      </th>
                      <td className="matched">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { (entity.matched) ? entity.matched : 0 }
                            </a>
                          </h4>
                        </div>
                      </td>
                      <td className="value">
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { entity.value }
                            </a>
                          </h4>
                        </div>
                      </td>
                      <td className="text-right">
                        <Button
                          color="secondary" size="md" type="button"
                          onClick={() => {
                            // Send to customer view
                            history.push(`/admin/customers/insights?personaId=${entity.id}`);
                          }}
                          style={{
                            width: 150
                          }}
                        >
                          <span className="btn-inner--icon mr-3">
                            <i className="ni ni-single-02" />
                          </span>
                          <span className="btn-inner--text">Matches</span>
                        </Button>
                        <UncontrolledDropdown group>
                            <Button
                              color="primary" size="sm" type="button"
                              onClick={() => {
                                // select entity
                                selectEntity(entity);
                              }}
                              style={{
                                width: 100
                              }}
                            >
                              <span className="btn-inner--icon mr-3">
                                <i className="ni ni-badge" />
                              </span>
                              <span className="btn-inner--text">Profile</span>
                            </Button>
                            <DropdownToggle
                              caret
                              color="primary"
                            />
                            <DropdownMenu>
                              <DropdownItem disabled>
                                Activity
                              </DropdownItem>
                              <DropdownItem disabled>
                                Settings
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <CardFooter className="py-4">

              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedPersonas = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report
}))(Personas);

export default ConnectedPersonas;
