// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    activities: [],
    filters: {
      timeframe: {
        startDate: "2022-11-01",
        endDate: "2022-11-30"
      }
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const researchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_RESEARCH: {
          console.log("Initializing research...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              filters: action.payload.filters
          };
        }
        case actionTypes.SET_ACTIVITIES: {
          console.log("Setting Activities: ", action.payload);

          // Update state
          return {
              ...state,
              activities: action.payload
          };
        }
        default:
            return state;
    }
};

export default researchReducer;
