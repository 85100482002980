/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';

// react plugin used to create charts
import { Bubble } from "react-chartjs-2";

// Fake data
import { faker } from '@faker-js/faker';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Progress,
  Button
} from "reactstrap";

// Register ChartJs
//ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  maintainAspectRatio: false
};

export const data = {
  datasets: [
    {
      label: 'Red dataset',
      data: Array.from({ length: 50 }, () => ({
        x: faker.datatype.number({ min: -100, max: 100 }),
        y: faker.datatype.number({ min: -100, max: 100 }),
        r: faker.datatype.number({ min: 5, max: 20 }),
      })),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Blue dataset',
      data: Array.from({ length: 50 }, () => ({
        x: faker.datatype.number({ min: -100, max: 100 }),
        y: faker.datatype.number({ min: -100, max: 100 }),
        r: faker.datatype.number({ min: 5, max: 20 }),
      })),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function ChartBlock({ profile, account, knowledge, model }) {


  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Customer Value
                </h6>
                <h5 className="h3 text-white mb-0">ROAS (First Purchase) vs. LTV</h5>
              </div>
              <div className="col">

              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                <ListGroup className="list my--3 bg-primary" flush>
                  { knowledge.entities.map((persona, index) => (
                    <ListGroupItem className="bg-primary border-0 px-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className="text-white">{persona.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color="info"
                            max="100"
                            value={persona.strength}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Bubble options={options} data={data} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedChartBlock = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  model: state.model
}))(ChartBlock);

export default ConnectedChartBlock;
