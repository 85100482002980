// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    domains: [
      {
        name: "Active",
        id: "@axon/commerce/customers",
        display: "91 Customers",
      },
      {
        name: "Staged",
        id: "@axon/marketing/campaigns",
        display: "6 Customers"
      },
      {
        name: "Review",
        id: "@axon/commerce/products",
        display: "12 Customers"
      },
      {
        name: "In-Progress",
        id: "@axon/commerce/orders",
        display: "10 Customers"
      }
    ],
    selectedDomain: {
      name: "Customer Personas",
      id: "@axon/commerce/customers"
    }
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const domainsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_ACCOUNT: {
          console.log("Initializing account...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              config: action.payload.config,
              clients: action.payload.clients
          };
        }
        default:
            return state;
    }
};

export default domainsReducer;
