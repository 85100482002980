/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// Sparklines
import { Sparklines, SparklinesLine, SparklinesBars, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// Firestore
import { firestore } from "./../../../firebase";

// core components
import KnowledgeHeader from "components/Headers/KnowledgeHeader.js";

// Auth actions
import {
  INITIALIZE_RESEARCH,
  SET_ACTIVITIES,
  SET_ACTIVE_CUSTOMER,
  SET_ACTIVE_DOMAIN,
  SET_ACTIVE_ENTITY
} from 'store/actions';

function Research({ profile, account, research, insights, dispatch }) {
  console.log("Research:", research);

  // Get history
  const history = useHistory();

  // Sync insights
  useEffect(() => {
    if (account.initialized && !research.initialized) {
      // Initialize insights
      dispatch({
        type: INITIALIZE_RESEARCH,
        payload: {
          filters: {
            timeframe: {
              startDate: "",
              endDate: ""
            }
          }
        }
      });
    }
  }, [ account ]);

  // Handle artwork selection
  const selectActivity = (activity) => {
    console.log("Selected Activity: ", activity);

    // // Got to review page
    // history.push(`/admin/knowledge/research/view/${activity.id}`);
  }

  // Select customer
  const selectCustomer = (customerId) => {
    // Go to customer
    history.push(`/admin/customers/insights/view/${customerId}`);
  }

  // Update customers when filters change
  useEffect(() => {
    // Query firestore
    firestore.collection("activity")
      .where("source.account", "==", "arthaus")
      .where("source.client", "==", "myarthaus")
      .orderBy("updated", "desc").limit(30)
      .onSnapshot((querySnapshot) => {
        // Iterate and append to list
        let activities = [];
        let activityData;
        querySnapshot.forEach((doc) => {
          // Get data
          activityData = doc.data();

          // Format and export
          if(activityData.data.details.display.indexOf('nan') < 0) {
            // Add
            activities.push(activityData);
          }
        });

        // Update research
        console.log(" -> Activities: ", activities.slice(0,10));
        dispatch({
          type: SET_ACTIVITIES,
          payload: activities.slice(0,10)
        });
      }, (error) => {
        console.log(error);
      });
  }, [ research.filters ]);

  return (
    <>
      <KnowledgeHeader
        headline="Research Activities"
        description="...."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Row>
              <div className="col">
                <Card>
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Recent Research Activity</h3>
                  </CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="domain" scope="col">
                          Domain
                        </th>
                        <th className="sort" data-sort="entity" scope="col">
                          Entity
                        </th>
                        <th className="sort" data-sort="status" scope="col">
                          Status
                        </th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody className="list">
                      { research.activities.map((activity, index) => (
                        <tr key={index}>
                          <th className="sort" data-sort="domain" scope="col">
                            { activity.data.details.domain }
                          </th>
                          <th className="sort" data-sort="entity" scope="col">
                            { activity.data.details.entity }
                          </th>
                          <th className="sort" data-sort="status" scope="col">
                            { activity.data.details.display }
                          </th>
                          <td className="text-right">
                            <Button
                              onClick={() => {
                                // Select customer
                                if(activity && activity.source) {
                                  // Select customer
                                  selectCustomer(activity.source.ref);
                                }
                              }}
                              className="btn-icon" color="secondary" type="button"
                            >
                              <span className="btn-inner--icon">
                                <i className="ni ni-single-02" />
                              </span>
                            </Button>
                            <UncontrolledDropdown group>
                                <Button
                                  color="primary" size="sm" type="button"
                                  onClick={() => {
                                    // select entity
                                    selectActivity(activity);
                                  }}
                                  style={{
                                    width: 120
                                  }}
                                >
                                  View
                                </Button>
                                <DropdownToggle
                                  caret
                                  color="primary"
                                />
                                <DropdownMenu>
                                  <DropdownItem disabled>
                                    Cancel
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <CardFooter className="py-4">
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedResearch = connect(state => ({
  profile: state.profile,
  account: state.account,
  research: state.research,
  insights: state.insights
}))(Research);

export default ConnectedResearch;
