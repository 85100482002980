/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// Authentication
import { auth, firestore } from "./../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";

import {
  SET_PERSONAS,
  SET_ACTIVE_ENTITY,
  SET_PERSONA,
  SET_QUERY
} from 'store/actions';

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";

// Persona (master)
const masterPersona = `
You are one of several possible buying persona's for a lobster delivery service retailer named Get Main Lobster that delivers fresh Maine lobster and seafood directly to customers doorsteps.

Here are the possible buying persona's that you might be and details about your purchase criteria.

Persona: Seafood Connoisseur
About: An Epicure Who Prioritizes Quality, Freshness, and Authentic Maine Lobsters Delivered Straight to the Doorstep
Primary Purchase Criteria: (1) Premium Freshness, (2) Rapid Delivery, (3) Authentic Maine Lobster

Persona: Nostalgic Consumer
About: For those who crave a taste of nostalgia with Fresh, Authentic Maine Lobsters Delivered to their Doorsteps
Primary Purchase Criteria: (1) Authenticity, (2) Speed of Delivery, (3) Quality of Packaging
`;

// Get last 7 days
let dates = [];
let values = [];
for (let i = 0; i < 7; i++) {
    // Setup default
    dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    values.push(0);
}

// Chart data placeholders
let chartData = {
  matchedCustomers: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Matched Customers",
            data: values,
          },
        ],
      };
    }
  }
};

// Query: Total Customers
async function getTotalCustomers(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get all current customers
  let customersQuery = {
    "events": [
          {
              "id": "Customer Created",
              "math": "dau",
              "name": "Customer Created",
              "type": "events",
              "order": 0,
              "properties": [
                  {
                      "key": "clientId",
                      "type": "event",
                      "value": [
                          `${account.config.defaultClient}`
                      ],
                      "operator": "exact"
                  }
              ]
          }
      ],
      "date_to": null,
      "display": "ActionsTable",
      "insight": "TRENDS",
      "interval": "month",
      "date_from": "all",
      "entity_type": "events",
      "refresh": true
  };

  // Execute query
  const customersResponse = await axios.post(eventsApiUrl, customersQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Parse customer count
  const totalCustomers = (customersResponse.data.result && customersResponse.data.result.length > 0) ? customersResponse.data.result[0].aggregated_value : 0;

  // Get all current customers
  let newCustomersQuery = {
    "events": [
        {
            "id": "Customer Created",
            "math": "dau",
            "name": "Customer Created",
            "type": "events",
            "order": 0,
            "properties": [
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.config.defaultClient}`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "date_to": null,
    "display": "ActionsTable",
    "interval": "day",
    "date_from": "-30d",
    "entity_type": "events",
    "refresh": true
  };

  // Execute query
  const newCustomersResponse = await axios.post(eventsApiUrl, newCustomersQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Parse new customres
  const newCustomers = (newCustomersResponse.data.result && newCustomersResponse.data.result.length > 0) ? newCustomersResponse.data.result[0].aggregated_value : 0;

  // Determine delta
  const totalCustomersPrevious = totalCustomers - newCustomers;
  const customerDelta = (totalCustomersPrevious > 0) ? 100.0 * (totalCustomers - totalCustomersPrevious) / totalCustomersPrevious : 100.0;

  // Return values
  return {
    totalCustomers: totalCustomers,
    newCustomers: newCustomers,
    customerDelta: customerDelta
  };
}

// Query: Matched Customers
function getMatchedCustomers(account, startDate, endDate, callback) {
  let query = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
        {
            "id": "Customer Activity",
            "math": "dau",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "properties": [
                {
                    "key": "properties.persona.version = '0.0.6'",
                    "type": "hogql",
                    "value": null
                },
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.config.defaultClient}`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "interval": "day",
    "entity_type": "events"
  }


  // Execute query
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;
  axios.post(eventsApiUrl, query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.config.events.api}`
      }
    }).then(res => {
        callback(res.data)
    })
    .catch((err) => {
        console.log(`ERROR: ${err}`);
    });
}

// Query: Persona Activity
function getPersonaActivity(account, startDate, endDate, callback) {
  // Define query data
  let query = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
        {
            "id": "Customer Activity",
            "math": "dau",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "properties": [
                {
                    "key": "properties.persona.version = '0.0.6'",
                    "type": "hogql",
                    "value": null
                },
                {
                    "key": "clientId",
                    "type": "event",
                    "value": [
                        `${account.config.defaultClient}`
                    ],
                    "operator": "exact"
                }
            ]
        }
    ],
    "interval": "day",
    "breakdown": "properties.persona.name",
    "entity_type": "events",
    "breakdown_type": "hogql"
  }

  // Execute query
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;
  axios.post(eventsApiUrl, query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${account.config.events.api}`
      }
    }).then(res => {
        callback(res.data)
    })
    .catch((err) => {
        console.log(`ERROR: ${err}`);
    });
}

function Home({ profile, account, knowledge, insights, report, model, dispatch }) {

  // Default states
  const [activeNav, setActiveNav] = React.useState(1);
  const [activeChartData, setActiveChartData] = React.useState("default");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setActiveChartData(activeChartData === "30-day" ? "90-day" : "30-day");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Get history
  const history = useHistory();

  // Customer / Persona Data
  const [ totalCustomers, setTotalCustomers ] = React.useState(0);
  const [ customerGrowth, setCustomerGrowth ] = React.useState(0.0);
  const [ personaActivity, setPersonaActivity ] = React.useState([]);

  // Load persona data
  React.useEffect(() => {
    if (account.initialized && !insights.initialized) {
      console.log("Loading Personas: ", account);

      // Query firestore
      firestore.collection("personas_v0.9")
        .where("account", "==", account.config.accountId)
        .where("client", "==", account.config.defaultClient)
        .onSnapshot((querySnapshot) => {
          // Iterate and append to list
          let personas = [];
          querySnapshot.forEach((doc) => { personas.push(doc.data().data); });

          // Update personas
          dispatch({
            type: SET_PERSONAS,
            payload: personas
          });
        }, (error) => {
          console.log(error);
        });

        // Customers: Update
        (async () => {
          // Get customer details
          const customerData = await getTotalCustomers(account, "2024-03-29", "2024-05-29T23:59:59");
          console.log("Customer Data: ", customerData);

          // Set customer data
          if(customerData) {
            setTotalCustomers(customerData.totalCustomers.toFixed(0));
            setCustomerGrowth(customerData.customerDelta);
          }
        })();

        // Graph: Matched Customers
        getMatchedCustomers(account, "2024-03-29", "2024-05-29T23:59:59", function(data) {
          // Parse results
          if(data.result && data.result.length > 0) {
            // Set chart data (7-day)
            chartData.matchedCustomers['30-day'] = (canvas) => {
              return {
                labels: data.result[0].days.slice(Math.max(data.result[0].days.length - 30, 0)),
                datasets: [
                  {
                    label: "Matched Customers",
                    data: data.result[0].data.slice(Math.max(data.result[0].data.length - 30, 0))
                  },
                ],
              };
            };

            // Set chart data (7-day)
            chartData.matchedCustomers['90-day'] = (canvas) => {
              return {
                labels: data.result[0].days,
                datasets: [
                  {
                    label: "Matched Customers",
                    data: data.result[0].data
                  },
                ],
              };
            };
          }

          // Set default flag
          setActiveChartData("30-day");
        });
    }
  }, [ account.initialized ]);

  // Load persona data
  React.useEffect(() => {
    if (knowledge.entities && knowledge.entities.length > 0) {
      console.log("Loading Persona Activity: ", knowledge.entities);

      // Graph: Persona Activity
      getPersonaActivity(account, "2024-03-29", "2024-05-29T23:59:59", function(data) {
        console.log("Persona Activity: ", data);

        // Format data
        let updatedPersonaActivities = [];
        for(var i = 0; i < data.result.length; i++) {
          // Get value
          let personaName = data.result[i].breakdown_value;

          // Calculate ranges
          let total30DayTarget = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 30, 0)));
          let total30DayPrevious = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 60, 0), Math.max(data.result[i].data.length - 30, 0)));

          // Fine matching Persona
          let matchedPersona = _.find(knowledge.entities, {
            name: personaName
          });

          // Setup values
          updatedPersonaActivities.push({
            id: matchedPersona ? matchedPersona.id : '',
            name: personaName,
            matches: total30DayTarget,
            delta: (total30DayPrevious > 0) ? 100.0 * (total30DayTarget - total30DayPrevious) / total30DayPrevious : 100.0
          });

          // // Calculate 30 Day
          // if(activeChartData === '30-day' || activeChartData === 'default') {
          //   // Calculate ranges
          //   let total30DayTarget = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 30, 0)));
          //   let total30DayPrevious = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 60, 0), Math.max(data.result[i].data.length - 30, 0)));
          //
          //   // Setup values
          //   updatedPersonaActivities.push({
          //     name: personaName,
          //     matches: total30DayTarget,
          //     delta: (total30DayPrevious > 0) ? 100.0 * (total30DayTarget - total30DayPrevious) / total30DayPrevious : 100.0
          //   });
          // }
          //
          // // Calcualte 7 Day
          // if(activeChartData === '7-day') {
          //   // Calculate ranges
          //   let total7DayTarget = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 7, 0)));
          //   let total7DayPrevious = _.sum(data.result[i].data.slice(Math.max(data.result[i].data.length - 14, 0), Math.max(data.result[i].data.length - 7, 0)));
          //
          //   // Setup values
          //   updatedPersonaActivities.push({
          //     name: personaName,
          //     matches: total7DayTarget,
          //     delta: (total7DayPrevious > 0) ? 100.0 * (total7DayTarget - total7DayPrevious) / total7DayPrevious : 100.0
          //   });
          // }
        }

        // Set new persona
        setPersonaActivity(updatedPersonaActivities);
      });
    }
  }, [ knowledge.entities ]);

  // Query iunput
  const [query, setQuery] = React.useState("");

  // Handle text query change
  const handleChange = (e) => {
    // Set query
    setQuery(e.target.value);
  }

  // Handle artwork selection
  const selectEntity = (entity) => {
    // Update local store
    dispatch({
      type: SET_ACTIVE_ENTITY,
      payload: entity
    });

    // Got to review page
    history.push(`/admin/customers/personas/view/${entity.id}`);
  }

  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="4">
            <Link to="/admin/customers/personas">
              <Card
                className="bg-gradient-primary border-0"
                style={{ cursor: 'pointer', height: 140 }}
              >
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        className="text-uppercase text-muted mb-0 text-white"
                        tag="h5"
                      >
                        Active Customers
                      </CardTitle>
                      <span className="h2 font-weight-bold mb-0 text-white">
                        { accounting.formatNumber(totalCustomers) }
                      </span>
                    </div>
                    <Col className="col-auto">
                      <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                        <i className="ni ni-single-02" />
                      </div>
                    </Col>
                  </Row>
                  <p className="mt-3 mb-0 text-sm">
                    <span className="text-white mr-2">
                      <i className="fa fa-arrow-up" /> { customerGrowth }%
                    </span>{" "}
                    <span className="text-info text-nowrap">(30 Days)</span>
                  </p>
                </CardBody>
              </Card>
            </Link>
          </Col>

          <Col xl="8">
            <Card
              className="bg-gradient-primary border-0"
              style={{
                height: 140
              }}
            >
              <CardBody>
                <Row>
                  <Col md="12">
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Ask a question about your customers..."
                      style={{
                        zIndex: 100000000,
                        position: 'absolute',
                        width: '90%',
                        top: 5
                      }}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Set persona
                          dispatch({
                            type: SET_PERSONA,
                            payload: {
                              persona: masterPersona
                            }
                          });

                          // Set initial query
                          if(query.length > 0) {
                            dispatch({
                              type: SET_QUERY,
                              payload: {
                                query: query
                              }
                            });
                          }

                          // Go to agent
                          history.push(`/admin/knowledge/agent`);
                        }
                      }}
                    />
                    <div
                      className="icon icon-shape bg-white text-dark rounded-circle shadow"
                      style={{
                        zIndex: 200000000,
                        position: 'absolute',
                        right: '2%',
                        top: -2,
                        height: 80,
                        width: 80
                      }}
                    >
                      <i className="ni ni-books" />
                    </div>
                  </Col>
                  <Col
                    md="12"
                    style={{
                      paddingTop: 65
                    }}
                  >
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Freshness Guarantee",
                        description: "their commitment to providing premium, fresh Maine lobsters directly to your doorstep, ensuring the highest quality and taste"
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      freshness guarantee
                    </Button>
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Source and Sustainability",
                        description: "their dedication to sourcing authentic Maine lobsters sustainably and supporting local fishermen and communities in the process"
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      source and sustainability
                    </Button>
                    <Button onClick={() => {
                      // Select persona
                      const topic = {
                        name: "Packaging and Presentation",
                        description: "their ability to ensure that our lobsters are delivered in high-quality packaging, maintaining their freshness and presentation throughout the delivery process."
                      };

                      // Set persona
                      dispatch({
                        type: SET_PERSONA,
                        payload: {
                          persona: masterPersona
                        }
                      });

                      dispatch({
                        type: SET_QUERY,
                        payload: {
                          query: `Can you tell me more about how you evaluate retailers like us on their ${topic.name}. Specifically, ${topic.description}.`
                        }
                      });

                      // Go to agent
                      history.push(`/admin/knowledge/agent`);
                    }} color="default" size="sm" type="button">
                      packaging and presentation
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="8">
            <Card className="bg-default" style={{
              minHeight: 508
            }}>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="surtitle">Activity</h6>
                    <h5 className="h3 mb-0">Matched Customers</h5>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem className="mr-2 mr-md-0">
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">90 Days</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">30 Days</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartData.matchedCustomers[activeChartData]}
                    options={chartOptions.options}
                    id="customer-activity"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="surtitle">Activity</h6>
                    <h5 className="h3 mb-0">Personas</h5>
                  </div>
                  <div className="col">
                    <Link to="/admin/customers/personas">
                      <Button className="float-right" color="secondary" size="sm" type="button">
                        View All
                      </Button>
                    </Link>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <ListGroup className="list my--3" flush>
                  { personaActivity.slice(0, 4).map((persona, index) => (
                    <ListGroupItem className="px-0">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                            <i className="ni ni-circle-08" />
                          </div>
                        </Col>
                        <div className="col ml--2">
                          <h4 className="mb-0">
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                              { persona.name }
                            </a>
                          </h4>
                          <small>
                            <a href="#">{ persona.matches } Recent Customers</a>
                          </small>
                          <br/>
                          <p className="mt-0 mb-0 text-sm">
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up" /> { persona.delta }%
                            </span>{" "}
                            <span className="text-nowrap">Since last month</span>
                          </p>
                        </div>
                        <Col className="col-auto">
                          <Button
                            color="primary" size="sm" type="button"
                            onClick={() => {
                              // Send to customer view
                              history.push(`/admin/customers/insights?personaId=${persona.id}`);
                            }}
                          >
                            View
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge,
  insights: state.insights,
  report: state.report,
  model: state.model
}))(Home);

export default ConnectedHome;
