// action - state management
import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    config: null,
    clients: []
};

// ===========================|| PRODUCTS REDUCER ||=========================== //

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_ACCOUNT: {
          console.log("Initializing Account...", action.payload);

          // Update state
          return {
              ...state,
              initialized: true,
              config: action.payload.config,
              clients: action.payload.clients
          };
        }
        default:
            return state;
    }
};

export default accountReducer;
