import { combineReducers } from 'redux';

// Account / Profile
import profileReducer from './profile';
import accountReducer from './account';

// Customer
import insightsReducer from './insights';

// Knowledge
import domainsReducer from './domains';
import researchReducer from './research';
import agentReducer from './agent';

// ...
import knowledgeReducer from './knowledge';
import reportReducer from './report';
import modelReducer from './model';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    profile: profileReducer,
    account: accountReducer,

    insights: insightsReducer,

    domains: domainsReducer,
    research: researchReducer,
    agent: agentReducer,

    knowledge: knowledgeReducer,
    model: modelReducer,
    report: reportReducer,
});

export default reducer;
